import React from "react"
import Layout from "../../components/modules/Layout"
import BodyBlocks from "../../components/technical/BodyBlocks"

const kickerPlaceholder = "Ein Platzhalter-Kicker"
const headlinePlaceholder = "Eine Platzhalter-Überschrift"
const subheadlinePlaceholder = "Eine Platzhalter-Unterzeile"

const imagePlaceholder = {
  filename:
    "https://a.storyblok.com/f/108683/1920x1080/d6600d2022/bg_placeholder_1080p_center.jpg",
}
const captionPlaceholder = "Ich bin so glücklich, mein Bester..."

const ImagesOnlyPage = () => {
  const body = [
    {
      component: "hero_block",
      kicker: kickerPlaceholder,
      headline: "1. Hero Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      image: imagePlaceholder,
    },
    {
      component: "gallery_block",
      kicker: kickerPlaceholder,
      headline: "2. Gallery Block – Thumbnails: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      image_items: [
        {
          image: imagePlaceholder,
          caption: captionPlaceholder,
        },
        {
          image: imagePlaceholder,
          caption: captionPlaceholder,
        },
        {
          image: imagePlaceholder,
        },
        {
          image: imagePlaceholder,
        },
      ],
    },
    {
      component: "gallery_block",
      kicker: kickerPlaceholder,
      headline: "3. Gallery Block – Slider: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      view: "slider",
      image_items: [
        {
          image: imagePlaceholder,
          caption: captionPlaceholder,
        },
        {
          image: imagePlaceholder,
          caption: captionPlaceholder,
        },
        {
          image: imagePlaceholder,
        },
        {
          image: imagePlaceholder,
        },
      ],
    },
    {
      component: "image_block",
      block_width: "content",
      kicker: kickerPlaceholder,
      headline: "4. Image Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      aspect_ratio: "16by9",
      image: imagePlaceholder,
      caption: captionPlaceholder,
    },
    {
      component: "image_block",
      kicker: kickerPlaceholder,
      headline: "5. Image Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      aspect_ratio: "16by9",
      image: imagePlaceholder,
      caption: captionPlaceholder,
    },
    {
      component: "image_block",
      block_width: "screen",
      kicker: kickerPlaceholder,
      headline: "6. Image Block: " + headlinePlaceholder,
      subheadline: subheadlinePlaceholder,
      aspect_ratio: "16by9",
      image: imagePlaceholder,
      caption: captionPlaceholder,
    },
  ]

  return (
    <Layout>
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default ImagesOnlyPage
